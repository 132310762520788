<template>
    <h1 class="dashboard-title">Мои билеты</h1>
    <div class="text info">
        <p class="mb-2"><b>Условия получения Билетов</b></p>
        <div>1. Для выполнения данного условия необходимо совершить платежи суммарно на 4 000 сомов в течение последних 5 дней.</div>
        <div>2. Учитываются платежи по QR-коду, оплата сервисов и услуг в каталоге (интернет, коммунальные услуги и др.)</div>
        <div>3. Условие увеличения последующих билетов сохраняется при достижении суммы платежей за последние 5 дней.</div>
        <a href="https://t.me/bitokkg_support" class="button button-success mt-3" target="_blank">Получить подробную информацию</a>
    </div>
    <div class="mt-4 mb-2">
        <h1 class="dashboard-title text-center d-block">До розыгрыша осталось</h1>
        <CountDown :labelColor="'#ffffff'" :mainColor="'#ffffff'" :deadlineDate="deadlineDate" :labels="labels"></CountDown>
    </div>
    <div class="row">
        <div class="col-lg col-md-6 col-sm-12">
            <div class="dashboard-personal-wrap ellipse ellipse-after">
                <div class="profile-info-column mt-0 refill">
                    <div>
                        <span>Ваши билеты</span>
                        <b>0</b>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg col-md-6 col-sm-12">
            <div class="dashboard-personal-wrap ellipse ellipse-after">
                <div class="profile-info-column mt-0 refill">
                    <div>
                        <span>Всего куплено</span>
                        <b>0.00 USDT</b>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg col-md-6 col-sm-12">
            <div class="dashboard-personal-wrap ellipse ellipse-after">
                <div class="profile-info-column mt-0 refill">
                    <div>
                        <span>Всего продано</span>
                        <b>0.00 USDT</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-personal-wrap">
        <span class="icon-text empty mb-4">Нет данных</span>
    </div>
</template>

<script>
import { Countdown } from 'vue3-flip-countdown'

export default {
    data() {
        return {
            deadlineDate: '2024-12-30T20:00:00',
            labels: {days: 'Дней', hours: 'Часов', minutes: 'Минуты', seconds: 'Секунд'}
        }
    },
    components: {
        CountDown: Countdown
    }
}
</script>